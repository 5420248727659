.messages-container {
    padding: 10px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: auto;
  }
  
  .search-box {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }
  
  .search-box input {
    width: 60%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 6px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .search-box input:focus {
    border-color: #007BFF;
  }
  
  .messages-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .messages-header,
  .messages-row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #eaeaea;
  }
  
  .messages-header {
    font-weight: bold;
    background-color: #007BFF;
    color: #fff;
    font-size: 14px;
  }
  
  .messages-row:nth-child(odd) {
    background-color: #f4f8fc;
  }
  
  /* 列样式 */
  .column {
    padding: 4px; /* 缩小列的内部间距到最小 */
    text-align: left;
    word-wrap: break-word;
    font-size: 12px;
  }
  
  /* 调整列宽比例，保持列之间最小间距 */
  .column:first-child {
    flex: 0.3; /* 时间列 */
  }
  
  .column:nth-child(2) {
    flex: 0.5; /* Tag 列 */
  }
  
  .column:nth-child(3) {
    flex: 3.5; /* Message 列 */
    word-break: break-word; /* 防止内容溢出 */
  }
  
  .loader {
    padding: 8px;
    text-align: center;
    color: #007BFF;
    font-size: 14px;
    font-weight: bold;
  }
  
  /* 移动端适配 */
  @media (max-width: 768px) {
    .search-box input {
      width: 90%;
    }
  
    .column {
      font-size: 12px;
      padding: 5px; /* 小屏幕进一步缩小间距 */
    }
  
    .column:first-child {
      flex: 0.3; /* 保持时间列比例 */
    }
  
    .column:nth-child(2) {
      flex: 0.8; /* 略微增加 Tag 列宽度 */
    }
  
    .column:nth-child(3) {
      flex: 5; /* 为第三列分配更多宽度 */
      word-break: break-word; /* 强制长单词换行 */
      white-space: normal; /* 允许内容自动换行 */
    }
  
    .messages-row {
      flex-wrap: wrap; /* 保证布局整齐 */
    }
  }
  