.config-manager {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .input-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .key-input {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .delete-button,
  .query-button,
  .add-button,
  .update-button {
    padding: 10px 15px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .query-button {
    background-color: #007bff;
    color: white;
  }
  
  .add-button {
    background-color: #28a745;
    color: white;
  }
  
  .update-button {
    background-color: #ffc107;
    color: white;
  }

  .delete-button{
    background-color: hsl(0, 100%, 51%);
    color: white;
  }
  
  .config-textarea {
    width: 100%;
    height: 800px;
    padding: 10px;
    border: 1px solid #ddd;
    margin-top: 20px;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
  }
  

  /* 美化下拉框样式 */
  .config-dropdown {
    width: 200px;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f8f8f8;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .config-dropdown:hover {
    border-color: #888; /* 鼠标悬停时改变边框颜色 */
  }