.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th, .user-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  max-width: 200px; /* 设置最大宽度限制 */
  overflow: hidden; /* 溢出内容隐藏 */
  text-overflow: ellipsis; /* 文本溢出显示省略号 */
}

.user-table th {
  background-color: #f2f2f2;
}

.user-table tr:hover {
  background-color: #f2f2f2;
}

.user-table button {
  margin-right: 5px;
}

.button-container {
  text-align: center;
  margin-top: 20px;
}

.button-container button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  background-color: #007bff; /* 默认背景颜色 */
  transition: background-color 0.3s ease; /* 添加过渡效果 */
}

.button-container button:hover {
  background-color: #0056b3; /* 鼠标悬停时的背景颜色 */
}

.button-container button:disabled {
  background-color: #ccc; /* 禁用状态的背景颜色 */
  cursor: not-allowed;
}


.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container label {
  display: block;
  margin-bottom: 10px;
}

.form-container input[type="text"],
.form-container input[type="email"],
.form-container input[type="date"],
.form-container select,
.form-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-container button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-container button[type="submit"] {
  background-color: #28a745;
}

.form-container button[type="button"] {
  background-color: #dc3545;
}


/* 搜索框样式 */
.search-container {
  margin: 5px; /* 外边距 */
  padding: 5px; /* 内边距 */
}

.search-container input {
  width: 100%; /* 宽度充满容器 */
  padding: 10px; /* 内边距 */
  border: 1px solid #ccc; /* 边框 */
  border-radius: 5px; /* 边框圆角 */
  font-size: 16px; /* 字体大小 */
}

.search-container textarea {
  width: 100%; /* 宽度充满容器 */
  padding: 10px; /* 内边距 */
  border: 1px solid #ccc; /* 边框 */
  border-radius: 5px; /* 边框圆角 */
  font-size: 16px; /* 字体大小 */
}

.search-container input:focus {
  outline: none; /* 去除默认的聚焦轮廓 */
  border-color: #007bff; /* 聚焦时边框颜色 */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2); /* 聚焦时的阴影 */
}

.search-container textarea:focus {
  outline: none; /* 去除默认的聚焦轮廓 */
  border-color: #007bff; /* 聚焦时边框颜色 */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2); /* 聚焦时的阴影 */
}

/* 按钮容器样式 */
.button-container {
  margin: 20px 0; /* 垂直外边距 */
  display: flex;
  justify-content: center; /* 水平居中按钮 */
}

.button-container button {
  padding: 10px 20px; /* 内边距 */
  font-size: 16px; /* 字体大小 */
  color: #fff; /* 字体颜色 */
  background-color: #007bff; /* 背景颜色 */
  border: none; /* 无边框 */
  border-radius: 5px; /* 边框圆角 */
  cursor: pointer; /* 鼠标悬停时显示指针手势 */
  transition: background-color 0.3s; /* 背景颜色变化的过渡效果 */
}

.button-container button:hover {
  background-color: #0056b3; /* 鼠标悬停时的背景颜色 */
}


.button-container2 {
  display: flex;
  justify-content: center; /* 水平居中按钮 */
}

.button-container2 button {
  font-size: 16px; /* 字体大小 */
  color: #fff; /* 字体颜色 */
  background-color: #007bff; /* 背景颜色 */
  border: none; /* 无边框 */
  border-radius: 5px; /* 边框圆角 */
  cursor: pointer; /* 鼠标悬停时显示指针手势 */
  transition: background-color 0.3s; /* 背景颜色变化的过渡效果 */
}

.button-container2 button:hover {
  background-color: #0056b3; /* 鼠标悬停时的背景颜色 */
}
/* 标题样式 */
.title-style {
  margin: 20px 0; /* 上下外边距 */
  font-size: 28px; /* 字体大小 */
  color: #333; /* 字体颜色 */
  text-align: center; /* 文本居中 */
  font-weight: bold; /* 字体加粗 */
  text-transform: uppercase; /* 文本转大写 */
  letter-spacing: 2px; /* 字间距 */
}

/* 添加一些文本阴影 */
.title-style {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

/* 如果你想要一个有颜色的背景 */
.title-bg {
  background-color: #f8f9fa; /* 轻微的背景颜色 */
  padding: 10px; /* 内边距 */
  border-left: 4px solid #007bff; /* 左边框 */
}

/* UserManagementPage.css */

/* 美化按钮 */
.custom-button {
  display: inline-block; /* 设置按钮为内联块级元素 */
  background-color: #007bff; /* 按钮背景颜色 */
  color: white; /* 按钮文字颜色 */
  border: none; /* 移除默认边框 */
  padding: 3px 4px; /* 内边距 */
  border-radius: 4px; /* 圆角 */
  cursor: pointer; /* 鼠标悬停时显示为手指 */
  font-size: 14px; /* 字体大小 */
  transition: background-color 0.3s, box-shadow 0.3s; /* 过渡效果 */
  white-space: nowrap; /* 防止按钮中的文字换行 */
}

/* 鼠标悬停效果 */
.custom-button:hover {
  background-color: #0056b3; /* 鼠标悬停时背景颜色 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 鼠标悬停时阴影效果 */
}

/* 禁用状态 */
.custom-button:disabled {
  background-color: #ccc; /* 禁用时背景颜色 */
  cursor: not-allowed; /* 禁用时鼠标样式 */
}

/* styles.css */
.green-text {
  color: green;
}


.user-export-container {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  align-items: center;
  margin: 20px;
}

.toggle-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
}

.toggle-checkbox {
  display: none; /* 隐藏原始复选框 */
}

.toggle-slider {
  position: relative;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  transition: background-color 0.3s;
  margin-right: 10px;
}

.toggle-slider:before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
  top: 1px;
  left: 1px;
}

.toggle-checkbox:checked + .toggle-slider {
  background-color: #4caf50; /* 选中时的背景颜色 */
}

.toggle-checkbox:checked + .toggle-slider:before {
  transform: translateX(20px); /* 选中时滑块移动 */
}

.export-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.export-button:hover {
  background-color: #0056b3; /* 按钮悬停时的背景颜色 */
}

.container {
  width: 80%;
  margin: 20px auto;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


/* 左侧：搜索框 + 按钮 */
.left-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* 右侧：编辑框 + GPT 按钮 */
.right-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* 编辑框 */
.editor-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  min-height: 40px;
  line-height: 1.5;
}


/* 让左右布局正确对齐 */
.content-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: flex-start; /* 确保左右对齐 */
}
