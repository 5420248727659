/* LoginPage.css */

/* 容器样式 */
.login-container {
    width: 400px;
    margin: auto;
    padding: 20px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  /* 标题样式 */
  .login-container h2 {
    text-align: center;
    color: #333;
  }
  
  /* 输入框容器样式 */
  .input-container {
    margin-bottom: 15px;
  }
  
  /* 输入框样式 */
  .input-container input {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  
  /* 错误消息样式 */
  .error-message {
    color: red;
    text-align: center;
    margin-bottom: 10px;
  }
  
  /* 登录按钮样式 */
  .login-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* 登录按钮悬停样式 */
  .login-button:hover {
    background-color: #0056b3;
  }
  